<script lang="ts" setup>
import type { Header } from '@/components/ui/data/DataTable.vue';
import type { Toast } from '@/types/toast';

// Composables
const { t } = useI18n();
const { getUnitsByMeterId } = useUnits();

// Stores
const alertService = alertStore();

// Data
const state = reactive({
  toast: inject('toast') as Toast,
  loading: false,
  isModalOpen: false,
  selectedAlert: null as number | null,
  search: '',
});

function editAlert(id: number) {
  state.selectedAlert = id;
  state.isModalOpen = true;
}
function closeModal() {
  state.isModalOpen = false;
  state.selectedAlert = null;
}

function units(meterId: number, type: string) {
  if (type === 'EPI') {
    return 'kWh / ' + getUnitsByMeterId(meterId).symbol;
  } else if (type === 'weekly_consumption') {
    return 'kWh';
  } else if (type === 'off_production') {
    return 'kW';
  } else {
    return '';
  }
}

const headers: Header[] = [
  {
    label: t('global.name'),
    key: 'name',
  },
  {
    label: t('global.system'),
    key: 'system',
  },
  {
    label: t('global.energy'),
    key: 'energy',
  },
  {
    label: t('alerts.type'),
    key: 'type',
  },
  {
    label: t('global.standard'),
    key: 'standard',
  },
  {
    label: t('global.tolerance'),
    key: 'tolerance',
  },
  {
    label: t('global.people_alerted'),
    key: 'people',
  },
  {
    label: t('global.edit_date'),
    key: 'updated-at',
  },
  {
    label: t('global.alerts_triggered'),
    key: 'triggered',
  },
  {
    label: t('global.action'),
    key: 'actions',
    align: 'center',
  },
];
</script>

<template>
  <div>
    <div v-if="!state.loading" class="flex flex-col gap-4">
      <ui-card :title="$t('alert.list_of_alerts')">
        <template #header>
          <ui-button left-icon="Plus" @click="state.isModalOpen = true">
            {{ t('global.configure_a_new_alert') }}
          </ui-button>
        </template>
        <ui-data-table :headers="headers" :items="alertService.getAlerts" @click-row="editAlert($event.id)">
          <template #item-system="{ item }">
            <ui-badge color="purple">
              {{ item.meter.area.name }}
            </ui-badge>
          </template>
          <template #item-energy="{ item }">
            <ui-badge :color="item.meter.meter_type.energy_type === 'Electricity' ? 'primary' : 'red'">
              {{ t(`global.energy_type.${item.meter.meter_type.energy_type}`) }}
            </ui-badge>
          </template>
          <template #item-type="{ item }">
            <ui-badge color="orange">
              {{ t(`alerts.type_${item.type}`) }}
            </ui-badge>
          </template>
          <template #item-standard="{ item }">
            {{ item.params.standard_value ? item.params.standard_value + ' ' + units(item.meter.id, item.type) : t('global.no_standard') }}
          </template>
          <template #item-tolerance="{ item }">
            {{ item.params.tolerance ? item.params.tolerance : t('global.no_tolerance') }}
            {{ item.params.tolerance ? ' %' : '' }}
          </template>
          <template #item-people="{ item }">
            <template v-if="item.alert_users">
              <div class="flex">
                <OAvatar
                  v-for="user in item.alert_users"
                  :key="user.id"
                  :user="user.user"
                  class="not-first:-ml-2.5"
                  size="1.5"
                  :tooltip="true"
                  :border="true"
                />
              </div>
            </template>
            <template v-else>
              {{ t('global.no_users_alerted') }}
            </template>
          </template>
          <template #item-updated-at="{ item }">
            {{ $dayjs(item._updated_at).format('DD/MM/YYYY') }}
          </template>
          <template #item-triggered="{ item }">
            {{ item.incidents.length }}
          </template>
          <template #item-actions="{ item }">
            <div
              class="flex items-center justify-center hover:text-gray-900 hover:bg-gray-100 rounded-lg h-[28px] w-[28px] cursor-pointer"
              @click="editAlert(item.id)"
            >
              <ui-icon name="Pencil" class="w-4 h-4 text-gray-600" />
            </div>
          </template>
        </ui-data-table>
      </ui-card>
    </div>
    <ui-loader v-else />
    <alert-modal v-if="state.isModalOpen" v-model="state.isModalOpen" :alert-id="state.selectedAlert" @update:model-value="closeModal()" />
  </div>
</template>
