<script setup lang="ts">
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import type { Meter } from '~/types/meter';
import type { Incident } from '~/types/incident';
import { useI18n } from 'vue-i18n';
import type { Alert } from '~/types/alerts';
import { cleanJsonBeforeSend } from '~/helpers/data';
import { useAlert } from '~/composables/useAlert';
import { cloneDeep } from '@apollo/client/utilities';

interface Form extends Alert {
  meter: Meter;
  incidents: Incident[];
  users: string[];
  area_id: number;
}

// Composables
const { t } = useI18n();
const route = useRoute();
const { alertTypeOptions, metersAsOptions, areasAsOptions, userAsOptions } = useConstants();
const { unit, typeFromSiteConfig } = useAlert();

// Stores
const { showToast } = toastStore();

// Props & Emits
const props = defineProps<{
  alertId: number | null;
  type?: 'weekly_consumption' | 'EPI' | 'off_production';
}>();
defineEmits<{
  refresh: [];
}>();
const modal = defineModel<boolean>();

// Data
const loading = ref<boolean>(false);
const deleting = ref<boolean>(false);

// Form
const schema = yup.object({
  name: yup.string().required(t('alert.form.action_name_is_required')),
  alert_users: yup.array(),
  area_id: yup.number().required(t('alert.form.area_is_required')),
  meter_id: yup.number().required(t('alert.form.meter_is_required')),
  type: yup.string().required(t('alert.form.type_is_required')),
  params: yup.object().shape({
    standard_value: yup.number().typeError(t('alert.form.potential_saving_must_be_a_number')),
    tolerance: yup.number().min(0, t('alert.form.tolerance_min_0')).typeError(t('alert.form.tolerance_min_0')),
  }),
});

const { handleSubmit, setValues, setFieldValue, values } = useForm<Form>({
  validationSchema: schema,
  initialValues: {
    params: {
      tolerance: 0,
    },
  },
});

// Mounted
onMounted(() => {
  if (modal.value) {
    loadData();
  }
});

// Init
const loadData = async () => {
  const alert = props.alertId ? alertStore().getAlert(props.alertId) : null;
  let meter = route.params.id ? meterStore().getMeter(parseInt(route.params.id as string)) : null;

  if (alert && !props.type) {
    meter = meterStore().getMeter(alert.meter_id);
    const { incidents, meter: _, alert_users, __typename, ...alertData } = alert;
    setValues(cloneDeep(alertData));
  }

  if (alert && alert.meter_id && meter) {
    const alertMeter = meterStore().getMeter(alert.meter_id);
    if (!alertMeter) return;
    setValues(cloneDeep(alert as any));
    setFieldValue('area_id', alertMeter?.area.id);
    setFieldValue('meter_id', alertMeter?.id);

    const alertUsers = alert.alert_users?.map((user) => user.user_id);
    if (alertUsers) {
      setFieldValue('users', alertUsers);
    }
  } else if (meter) {
    setFieldValue('area_id', meter?.area.id);
    setFieldValue('meter_id', meter?.id);
  } else {
    setFieldValue('area_id', areasAsOptions()[0].key);
  }
};

// Methods
const deleteAlert = async () => {
  deleting.value = true;
  try {
    if (!props.alertId) return;
    await alertStore().deleteAlert(props.alertId);
    modal.value = false;
    showToast({
      type: 'success',
      title: t('global.success'),
      message: t('alert.alert_deleted'),
    });
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
  deleting.value = false;
};

const createAlert = handleSubmit(async () => {
  loading.value = true;
  try {
    const data = await cleanJsonBeforeSend({ jsonObject: values, keys: ['area_id', 'meter', 'id', 'alert_users'] });
    await alertStore().createAlert(data);
    modal.value = false;
    showToast({
      type: 'success',
      title: t('global.success'),
      message: t('alert.alert_created'),
    });
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }
  loading.value = false;
});

const editAlert = handleSubmit(async () => {
  loading.value = true;

  try {
    const data = await cleanJsonBeforeSend({
      jsonObject: values,
      keys: ['area_id', 'meter', 'incidents', 'alert_users'],
    });

    alertStore().updateAlert(data);

    modal.value = false;
    showToast({
      type: 'success',
      title: t('global.success'),
      message: t('alert.alert_created'),
    });
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: t('global.message_error') });
    throw error;
  }

  loading.value = false;
});

// Computed
const metersItems = computed(() => {
  if (!values.area_id) return [];
  return metersAsOptions(values.area_id);
});

const alertTypeItems = computed(() => {
  if (props.type) return alertTypeOptions(props.type);
  else if (values.area_id && values.meter_id) return typeFromSiteConfig(values.area_id, values.meter_id);
  else return [];
});

const tolerance = computed(() => {
  if (values.type === 'EPI' && values.params.tolerance) {
    return ((1 + (values.params.tolerance ?? 0) / 100) * values.params.standard_value).toFixed(2);
  } else if (values.params.tolerance) {
    return ((1 + values.params.tolerance / 100) * values.params.standard_value).toFixed(0);
  }
  return null;
});

const computedUnit = computed(() => unit());

watch(
  () => modal.value,
  () => {
    loadData();
  },
);

// Reset field when change the area
watch(
  () => [values.area_id, values.meter_id],
  () => {
    if (!metersItems.value?.find((el) => el.key === values.meter_id)) {
      setFieldValue('meter_id', metersItems.value[0]?.key);
    } else if (!alertTypeItems.value?.find((el: { key: string }) => el.key === values.type)) {
      setFieldValue('type', alertTypeItems.value[0].key);
    }
  },
);
</script>
<template>
  <ui-modal v-model="modal" width="700" :title="alertId ? t('alert.update_alert') : t('alert.create_alert')">
    <div class="grid grid-cols-2 gap-x-2">
      <ui-form-input-text name="name" required :placeholder="t('alert.form.name_placeholder')" :label="t('alert.form.alert_name')" />
      <ui-form-input-multi-select name="users" :label="t('alert.form.people_to_alert')" :items="userAsOptions()" />
      <div class="bg-gray-50 rounded-md col-span-2 p-4 mb-4 grid grid-cols-2 gap-x-2">
        <h2 class="font-semibold text-start text-gray-700 mb-4 col-span-2">{{ t('global.alert_me_for') }}</h2>
        <ui-form-input-select
          name="area_id"
          :label="t('alerts.system')"
          :disabled="type ? true : false"
          required
          :items="areasAsOptions()"
        />
        <ui-form-input-select
          name="meter_id"
          :label="t('global.energy')"
          required
          :disabled="metersItems.length === 0 || type ? true : false"
          :items="metersItems"
        />
        <ui-form-input-select
          name="type"
          :label="t('alerts.type')"
          required
          :disabled="alertTypeItems.length === 0 || type ? true : false"
          :items="alertTypeItems"
        />
      </div>
      <div class="bg-gray-50 rounded-md col-span-2 p-4 grid grid-cols-2 gap-x-2">
        <h2 class="font-semibold text-start text-gray-700 mb-4 col-span-2">{{ t('alert.params') }}</h2>
        <ui-form-input-text
          v-if="values.type === 'weekly_consumption'"
          :label="t('alert.form.standard_value')"
          type="number"
          placeholder="1000"
          name="params.standard_value"
          right-text="kWh"
          required
        />
        <ui-form-input-text
          v-else-if="values.type === 'off_production'"
          :label="t('alert.form.standard_value')"
          type="number"
          placeholder="400"
          name="params.standard_value"
          right-text="kW"
          required
        />
        <ui-form-input-text
          v-else
          name="params.standard_value"
          :label="t('alert.form.standard_value')"
          type="number"
          placeholder="800"
          :right-text="`${t('global.kwh')} / ${computedUnit}`"
          required
        />

        <ui-form-input-text name="params.tolerance" type="number" :label="t('global.tolerance')" placeholder="2" right-text="%" />

        <p v-if="values?.params?.tolerance" class="text-gray-400 text-sm col-span-2 text-start">
          {{
            t('alerts.alert_triggers_when_value', {
              threshold: tolerance,
              unit:
                values.type === 'off_production'
                  ? 'kW'
                  : t('global.kwh') + (values.type === 'weekly_consumption' ? '' : ` / ${computedUnit}`),
            })
          }}
        </p>
      </div>
    </div>
    <template #footer>
      <ui-button v-if="alertId" color="delete" :loading="deleting" @click="deleteAlert">
        {{ t('global.delete') }}
      </ui-button>
      <ui-button :loading="loading" @click="props.alertId ? editAlert() : createAlert()">
        {{ t('global.save') }}
      </ui-button>
    </template>
  </ui-modal>
</template>
