import { Energy_Types_Enum } from '~/types/graphql/graphql';
import { useUnits } from './useUnits';

export const useAlert = () => {
  // Data
  const route = useRoute();
  const { alertTypeOptions } = useConstants();
  const { getUnitsByMeter, getUnitsByMeterId } = useUnits();

  // Functions
  const unit = (meter_id?: number) => {
    if (route.params.id) {
      return getUnitsByMeter.value.symbol;
    } else if (meter_id) {
      return getUnitsByMeterId(meter_id).symbol;
    } else {
      return 'kg';
    }
  };

  const typeFromSiteConfig = (areaId: number, meter_id: number) => {
    const selectedSite = globalStore().getSelectedSite;
    const site = siteStore().getSite(selectedSite[0]);
    const area = areaStore().getArea(areaId);
    const meter = meterStore().getMeter(meter_id);
    const isElectricityMeter = meter?.meter_type.energy_type === Energy_Types_Enum.Electricity;

    return alertTypeOptions().filter((option: any) => {
      if (option.key === 'off_production' && site.configs?.production_times.length > 0 && isElectricityMeter) {
        return true;
      } else if (option.key === 'EPI' && area?.production_reporting_frequency && isElectricityMeter) {
        return true;
      } else if (option.key === 'weekly_consumption') {
        return true;
      }
      return false;
    });
  };

  return { unit, typeFromSiteConfig };
};
